<template>
  <div>
    <LinkAccounts></LinkAccounts>
  </div>
</template>

<script>
// components
import LinkAccounts from '@/components/user/LinkAccounts.vue'

export default {
  components: { LinkAccounts }
}
</script>